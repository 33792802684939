<script>
export default {
  name: 'ProjectMaterial',
  page: {
    title: 'Υλικό'
  }
}
</script>

<template>
  <div>
    <div class="page-header">
      <b-container class="bv-example-row">
          <h1 class="title">Υλικό</h1>
      </b-container>
    </div>
    <div class="page-body">
      <b-container class="bv-example-row">
        <p class="page-text">
          Μπορείτε να δείτε και να κατεβάσετε το Εγχειρίδιο Χρήσης <a class="download-link" href="docs/user-manual.pdf" download>εδώ</a>
        </p>
        <p class="page-text">
          Μπορείτε να δείτε και να κατεβάσετε το Εγχειρίδιο Χρήσης του συστήματος <a class="download-link" href="docs/admin-manual.pdf" download>εδώ</a>
        </p>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './../../assets/scss/abstracts' as *;

.page-header {
  background-color: $white;
  padding: $s-xl 0 $s-s 0;
}

.title {
  color: $black;
  font-family: "Waiting for the Sunrise", cursive;
  padding: $s-s 0;
}

.page-body {
  background-color: $alto;
  color: $black;
  padding: $s-xl 0;
}

.page-text {
  line-height: $s-xl;
}

.download-link {
  color: $red;

  &:active,
  &:visited,
  &:focus {
    color: $black;
}

}
</style>
